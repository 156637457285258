// @font-face {
// 	font-family: 'Inter';
// 	font-style: oblique 0deg -10deg;
// 	font-weight: 400 800;
// 	font-display: swap;
// 	src: url(/fonts/Inter.var.woff2) format('woff2');
// }

@font-face {
	font-family: 'IBM Plex Mono';
	font-style: oblique 0deg -10deg;
	font-weight: 400;
	font-display: swap;
	src: url(/fonts/IBMPlexMono-Regular.woff2) format('woff2');
}

em, i {
	font-style: italic;
	@supports (font-variation-settings: normal) {
		font-variation-settings: 'slnt' -10;
		font-style: oblique 10deg;
	}
}

:root {
	/////////////////////////////
	// Base Colors
	/////////////////////////////
	--clr-blue: #0092db;
	--clr-orange: #ff9c44;
	--clr-pink: #ff91e2;
	--clr-red: #fe4e60;
	--clr-yellow: #fee370;
	--clr-green: #286a56;
	--clr-purple: #2a2f5f;
	--clr-gray: #a7bdc1;
	--clr-white: #f3f3f3;
	--clr-black: #0E1012;

	--color-blue: 0 146 219;
	--color-orange: 255 156 68;
	--color-pink: 255 145 226;
	--color-red: 254 78 96;
	--color-yellow: 254 227 112;
	--color-green: 40 106 86;
	--color-purple: 42 47 95;
	--color-gray: 167 189 193;
	--color-white: 243 243 243;
	--color-black: 14 16 18;

	--color-blue-500: 60 92 118;

	/////////////////////////////
	// Typography Settings
	/////////////////////////////
	--font-family: 'Inter';
	--font-family-heading: 'Merriweather', 'Times New Roman', serif;
	--font-family-fallback: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', system-ui, sans-serif !important;
	--font-family-monospace: 'IBM Plex Mono', 'Menlo', monospace;
	// Dynamic Type Scale
	--v-type-scale: 1.414;
	--v-x1: var(--v-type-scale);
	--v-x2: calc(var(--v-x1)*var(--v-x1));
	--v-x3: calc(var(--v-x2)*var(--v-x1));
	--v-x4: calc(var(--v-x3)*var(--v-x1));
	--v-x5: calc(var(--v-x4)*var(--v-x1));
	--font-size-2xs: calc(1rem / var(--v-x3)); // 5.65941777
	--font-size-xs: calc(1rem / var(--v-x2)); // 8.00241673
	--font-size-xsm: calc(1rem / var(--v-x2) * 1.314159); // 10.51644797
	--font-size-sm: calc(1rem / var(--v-x1)); // 11.31541726
	--font-size-2sm: calc(1rem / var(--v-x1) * 1.314159); //14.14427157
	--font-size-base: 1rem;
	--font-size-md: calc(1rem * var(--v-x1)); // 22.624
	--font-size-lg: calc(1rem * var(--v-x2)); // 31.990336
	--font-size-xl: calc(1rem * var(--v-x3)); // 45.2343351
	--font-size-2xl: calc(1rem * var(--v-x4)); // 63.96134983

	/////////////////////////////
	// Spacing Settings
	/////////////////////////////
	--span-inline: block;
	--padding-width: .75rem;
	--scrollbar-width: 15px;
	--layout-width: calc(100vw - var(--scrollbar-width));

	/////////////////////////////
	// Animation Settings
	/////////////////////////////
	--duration-base: 120ms;
	--timing-base: cubic-bezier(0.65, 0, 0.35, 1);

	/////////////////////////////
	// Default Theme ("dark")
	/////////////////////////////
	--is-dark-mode: /**/;

	--clr-text: #bebfbf;
	--clr-text-emphasis: #4ab0fb;
	--clr-text-bright: #f4f5f4;
	--clr-text-dim: #90959a;
	--clr-text-dimmest: #3d3d3d;
	--clr-text-dark: var(--clr-black);
	--color-text: 190 191 191;
	--color-text-bright: 244 245 244;
	--color-text-dark: 14 16 18;

	--clr-bg: #202224;
	--clr-bg-noise: #1b1b1d;
	--clr-bg-nav: #2d2d32;
	--clr-nav: #292a2e;
	--clr-nav-highlight: #3d3d3d;
	--clr-nav-shadow: #1f1f1f;
	--color-bg: 32 34 36;
	--color-nav: 41 42 46;
	--color-nav-shadow: 31 31 31;

	--shadow-color: 210deg 9% 4%;
	--shadow-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36), 0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36), 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36), 5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);

	--color-nav: 41 42 46;
	--bg-noise: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCI+PGZpbHRlciBpZD0ibm9pc2UiIHg9IjAiIHk9IjAiPjxmZVR1cmJ1bGVuY2UgdHlwZT0iZnJhY3RhbE5vaXNlIiBiYXNlRnJlcXVlbmN5PSIuOTUiIHN0aXRjaFRpbGVzPSJzdGl0Y2giPjwvZmVUdXJidWxlbmNlPjwvZmlsdGVyPjxyZWN0IHdpZHRoPSIzMDAiIGhlaWdodD0iMzAwIiBmaWx0ZXI9InVybCgjbm9pc2UpIiBvcGFjaXR5PSIuMDciPjwvcmVjdD48L3N2Zz4=);
	--bg: var(--bg-noise), var(--clr-bg-noise, #1b1b1d);
	--bg-even: var(--bg-noise), rgb(27 27 29 / 30%);
	--bg-nav: var(--bg-noise), linear-gradient(var(--clr-bg-nav, #2d2d32),var(--clr-nav-shadow, #1f1f1f));
	--bg-nav-vertical: var(--bg-noise), linear-gradient(to left, var(--clr-bg-nav, #2d2d32),var(--clr-nav-shadow, #1f1f1f));
	--bg-nav-short: var(--bg-noise), linear-gradient(var(--clr-bg-nav, #2d2d32),var(--clr-nav-shadow, #1f1f1f) 170%);
	--shadow: 0 0.625rem 2.5rem -0.625rem rgb(var(--color-black) / 30%), 0 0.625rem 1.25rem -1rem rgb(var(--color-black) / 20%);
	--shadow-up: 0 0.625rem 2.5rem 0.625rem rgb(var(--color-black) / 40%), 0 0.625rem 1.25rem 1rem rgb(var(--color-black) / 30%);
	--glow: 0 0 1.5rem .25rem rgb(var(--color-blue) / 100%),0 0 0.5rem 0.015rem rgb(var(--color-white) / 100%);
	--glow-xl: 0 0 1.5rem .25rem rgb(var(--color-blue) / 100%),0 0 4.5rem .25rem rgb(var(--color-blue) / 50%), 0 0 0.5rem 0.015rem rgb(var(--color-white) / 100%);
	--bg-nav-a: var(--bg-noise), linear-gradient(rgba(var(--color-nav, #2d2d32) / 75%),rgba(var(--color-nav-shadow, #1f1f1f)/75%));

	--blur: 1px;

	/////////////////////////////
	// Grid Layout Settings
	/////////////////////////////
	// --gridTemplateRowsMobile: 0 0 1fr 0 0;
	// --gridTemplateColumnsMobile: 1fr;
	// --gridTemplateRows: 1fr;
	// --gridTemplateColumns: 0 0.625rem 1fr ;
}

[data-theme="light"] {
	--clr-text: #414040;
	--clr-text-emphasis: #4ab0fb;
	--clr-text-bright: #0b0a0b;
	--clr-text-dim: #35322f;
	--clr-text-dimmest: #c2c2c2;
	--clr-text-dark: var(--clr-white);
	--color-text-bright: 11 10 11;
	--color-text-dark: 243 243 243;

	--clr-bg: #f8fcfb;
	--clr-bg-noise: #e8e6e3;

	--clr-bg-nav: #d2d2cd;// #2d2d32;
	--clr-nav: #d6d5d1;
	--clr-nav-highlight: #e8e6e3;
	--clr-nav-shadow: #c2c2c2;

	--color-bg: 248 252 251;
	--color-nav: 214 213 209;
	--color-nav-shadow: 194 194 194;

	--bg-noise: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCI+PGZpbHRlciBpZD0ibm9pc2UiIHg9IjAiIHk9IjAiPjxmZVR1cmJ1bGVuY2UgdHlwZT0iZnJhY3RhbE5vaXNlIiBiYXNlRnJlcXVlbmN5PSIuOCIgc3RpdGNoVGlsZXM9InN0aXRjaCI+PC9mZVR1cmJ1bGVuY2U+PC9maWx0ZXI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiIGZpbHRlcj0idXJsKCNub2lzZSkiIG9wYWNpdHk9Ii4yIj48L3JlY3Q+PC9zdmc+);
	--bg: var(--bg-noise), var(--clr-bg-noise, #FFFFFF);

	--bg-nav: var(--bg-noise), linear-gradient(var(--clr-nav, #d6d5d1),var(--clr-nav-shadow, #c2c2c2));
	--bg-nav-short: var(--bg-noise), linear-gradient(var(--clr-nav, #d6d5d1),var(--clr-nav-shadow, #c2c2c2) 170%);
}

html,body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	background-color: var(--clr-bg);
	color: var(--clr-text);
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

body.dragging {
	cursor: grabbing !important;
}

html {
	// height: -webkit-fill-available;
	-webkit-text-size-adjust: none;
	font-family: var(--font-family-fallback);
	font-size: 16px;
	overflow: hidden;
}
html.landing {
	overflow-y: scroll;
	overflow-x: hidden;
}
html:not(.landing) {
	overscroll-behavior: none;
}

#__next {
	height: 100%;
	width: 100%;
}

@supports (font-variation-settings: normal) {
	html {
		font-family: var(--font-family);
	}
}

html.borderBox body {
	background: var(--clr-black) !important;
}
html.borderBox * {
	box-sizing: border-box;

	// TODO: fix button dimension system
	.buttonWrap  {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.button {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.hidden {
	display: none !important;
	visibility: hidden;
	opacity: 0;
	overflow: hidden;
	height: 0  !important;
	width: 0 !important;
	padding: 0 !important;
}

.visually-hidden:not(:focus):not(:active) {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	width: 1px;
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	margin: -1;
	clip: 'rect(0, 0, 0, 0)';
	word-wrap: normal;
}
.noscroll {
	overflow: hidden;
	height: 100vh;
	width: 100vw;
}

.srOnly {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border-width: 0;
}



// Reset range slider
input[type=range] {
	-webkit-appearance: none;
	width: 100%;
	background: transparent;
}
input[type=range]::-webkit-slider-thumb {
	--webkit-appearance: none;
}
input[type=range]::-ms-track {
	width: 100%;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

div:focus {
	outline-color: var(--clr-blue);
	outline-style: solid;
	outline-width: 0.0625rem;
	outline-offset: -0.0625rem;
}



.mobile-only,
.desktop-up,
.not-desktop,
.desktop-only {
	display: none !important;
}

@media (min-width: 768px) {
	.desktop-only {
		display: flex !important;
	}
}
@media (max-width: 450px) {
	.mobile-only {
		display: flex !important;
	}
}
@media (min-width:992px) {
	.desktop-up {
		display: flex !important;
	}
}
@media (max-width: 992px) {
	.not-desktop {
		display: flex !important;
	}
}